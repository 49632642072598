/* import __COLOCATED_TEMPLATE__ from './header.hbs'; */
/* RESPONSIBLE TEAM: team-purchase-experience */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import { isPresent } from '@ember/utils';
import { keepLatestTask } from 'ember-concurrency-decorators';
import Component from '@glimmer/component';
import {
  PRODUCT_TOURS_ESSENTIAL_ID,
  SURVEYS_BASE_ID,
  SMS_ID,
  SWITCH_BASE_ID,
  WHATSAPP_BASE_ID,
  ANSWER_BOT_ESSENTIAL_ID,
  SUPPORT_AUTOMATION_BASE_ID,
  ANSWER_BOT_ID,
} from 'embercom/lib/billing';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { addonData, addonCardData } from 'embercom/lib/intershop/intershop';
import PricingMetric from 'embercom/lib/purchase/pricing-metric';
import { INCLUDED_TIERS_FROM_PRICING_ENDPOINT } from 'embercom/lib/billing';
import type Product from 'embercom/models/product';
import type RouterService from '@ember/routing/router-service';
import type CatalogApi from 'embercom/services/catalog-api';
import type Plan from 'embercom/models/plan';
import { taskFor } from 'ember-concurrency-ts';
import type IntershopService from 'embercom/services/intershop-service';
import type IntlService from 'embercom/services/intl';

interface Args {
  product: Product;
  plan: Plan;
}

export default class HeaderComponent extends Component<Args> {
  @service declare intl: IntlService;
  @service declare router: RouterService;
  @service declare customerService: any;
  @service declare appService: any;
  @service declare catalogApi: CatalogApi;
  @service declare intershopService: IntershopService;

  get isSMS(): boolean {
    return this.args.product.id === SMS_ID;
  }

  get productName(): string {
    // special case where product name is being changed to reflect tooltips
    return this.args.product.name === 'Product Tours'
      ? 'Product Tours and Tooltips'
      : this.args.product.name;
  }

  get addonDetails(): any {
    return addonCardData(this.args.plan.id);
  }

  get addonData(): any {
    return addonData(this.args.plan.id);
  }

  get addonDescription(): string {
    if (this.addonDetails && this.addonDetails.addonProductPageDescription) {
      return this.intl.t(this.addonDetails.addonProductPageDescription);
    } else {
      return '';
    }
  }

  get addonSubDescription(): string {
    if (this.addonDetails && this.addonDetails.addonProductPageSubDescription) {
      return this.intl.t(this.addonDetails.addonProductPageSubDescription);
    } else {
      return '';
    }
  }

  get canUpgrade(): boolean {
    return this.intershopService.canSelfServeUpgradeToPlan(this.args.plan);
  }

  get addonIcon(): string {
    return this.addonDetails?.icon;
  }

  get pricingModelIdentifier(): string {
    return this.customerService?.currentPricingModelIdentifier;
  }

  get planPricing(): any {
    let priceLoaded = this.customerService.isPriceLoaded(
      [this.args.plan.idAsNumber],
      this.pricingModelIdentifier,
    );
    if (priceLoaded) {
      return this.customerService.getPriceFromPlanIds(
        [this.args.plan.idAsNumber],
        this.pricingModelIdentifier,
      );
    } else {
      taskFor(this.getPrices).perform();
      return this.customerService.getPriceFromPlanIds(
        [this.args.plan.idAsNumber],
        this.pricingModelIdentifier,
      );
    }
  }

  get showMultiplePrices(): boolean {
    return this.addonData?.allowedPricingMetricsCharge?.length > 0;
  }

  get hasCustomTooltipContent(): boolean {
    return isPresent(this.addonData.customTooltipContent);
  }

  get customTooltipContent(): string {
    return this.addonData.customTooltipContent;
  }

  get pricingChargeMetrics(): any {
    if (!this.planPricing) {
      return null;
    }
    let allowedPricingMetricKeys = this.addonData.allowedPricingMetricsCharge;

    let charges = this.planPricing.breakdown.firstObject.charges;
    return charges
      .filter((charge: any) => allowedPricingMetricKeys.includes(charge.pricing_metric))
      .map((metric: any) => {
        let pricingMetricClass = new PricingMetric(metric, this.intl);
        return {
          name: pricingMetricClass.pluralize(),
          amount: pricingMetricClass.formattedMinimumPaidTier(),
        };
      });
  }

  get canRequestFreeTrialViaTicketing() {
    return this.args.product.trialable && this.appService.app.canSelfManageContract;
  }

  @action
  trialConfirmationAction() {
    switch (this.args.plan.id) {
      case PRODUCT_TOURS_ESSENTIAL_ID:
        return this.router.transitionTo('apps.app.outbound.tour', {
          queryParams: { startTour: true },
        });
      case SURVEYS_BASE_ID:
        return this.router.transitionTo('apps.app.outbound.survey', {
          queryParams: { onboardingVideoDisplayed: true },
        });
      case WHATSAPP_BASE_ID:
        return this.router.transitionTo('apps.app.appstore.app-package', 'whatsapp-fqam');
      case SWITCH_BASE_ID:
        return this.router.transitionTo('apps.app.settings.switch.index');
      case ANSWER_BOT_ESSENTIAL_ID:
        return this.router.transitionTo('apps.app.automation.resolution-bot.answers');
      case SUPPORT_AUTOMATION_BASE_ID:
        return this.router.transitionTo('apps.app.automation.resolution-bot.answers');
      default:
        return;
    }
  }

  @action
  requestFreeTrial() {
    let params = {
      app_id: this.appService.app.id,
      product_name: this.args.product.name,
      admin_id: this.appService.app.currentAdmin.id,
      admin_email: this.appService.app.currentAdmin.email,
    };
    taskFor(this.catalogApi.requestFreeTrial).perform(params);
  }

  @keepLatestTask *getPrices() {
    yield this.customerService.bulkLoadPrices([
      {
        planIds: [this.args.plan.idAsNumber],
        source: 'intershop-add-on-header-route',
        includePlanCombinationValidation: true,
        includeTiers: INCLUDED_TIERS_FROM_PRICING_ENDPOINT,
      },
    ]);
  }

  get isResolutionBot(): boolean {
    return this.args.product.id === ANSWER_BOT_ID;
  }

  get featureKey(): string {
    return this.args.plan.features.firstObject.key;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Intershop::Products::AddOns::Header': typeof HeaderComponent;
    'intershop/products/add-ons/header': typeof HeaderComponent;
  }
}
