/* import __COLOCATED_TEMPLATE__ from './intercomrade-warning.hbs'; */
/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import { inject as service } from '@ember/service';
import Component from '@ember/component';
import { tracked } from '@glimmer/tracking';

interface Args {}

interface Signature {
  Args: Args;
  Blocks: any;
}

export default class IntercomradeWarning extends Component<Signature> {
  @service appService: any;
  @tracked app: any;

  constructor() {
    super(...arguments);
    this.app = this.appService.app;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    IntercomradeWarning: typeof IntercomradeWarning;
  }
}
