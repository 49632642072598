/* import __COLOCATED_TEMPLATE__ from './single-primary-product.hbs'; */
/* RESPONSIBLE TEAM: team-purchase-experience */
import Component from '@glimmer/component';
import { CORE_GOOD_ID, CORE_SCALE_ID } from 'embercom/lib/billing';
import type Plan from 'embercom/models/plan';
import type Product from 'embercom/models/product';

interface Args {
  product: Product;
  isSalesforceContracted: boolean;
}

export default class SinglePrimaryProductComponent extends Component<Args> {
  get isSalesforceContracted(): boolean {
    return this.args.isSalesforceContracted;
  }

  /**
   * for VBP 1.X
   *  - hide Start for Salesforce Contracted
   *  - hide Scale for non Salesforce Contracted
   */
  get excludedPlans(): Array<string> {
    return this.isSalesforceContracted ? [CORE_GOOD_ID] : [CORE_SCALE_ID];
  }

  get filteredPlansForProduct(): Array<Plan> {
    return this.args.product.plans.filter((plan) => !this.excludedPlans.includes(plan.id));
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Intershop::Home::SinglePrimaryProduct': typeof SinglePrimaryProductComponent;
    'intershop/home/single-primary-product': typeof SinglePrimaryProductComponent;
  }
}
